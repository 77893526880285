import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import UploadProjectsPage from "./pages/UploadProjectsPage";
import ProjectsPage from "./pages/ProjectsPage";
import {useState} from "react";
import LoginComponent from "./component/LoginComponent";
function App() {

    const [loggedIn, setLoggedIn] = useState(localStorage.getItem('loggedIn'));

    const setLogin = (boolValue) => {
        localStorage.setItem('loggedIn', boolValue);
        setLoggedIn(boolValue);
    }

    return (

        <BrowserRouter>
            <Routes>
                 { loggedIn ? (<>
                <Route path="/*" element={<UploadProjectsPage/>} />
                <Route path="/projects" element={<ProjectsPage />} />
                 </>) : (<Route path="/*" element={<LoginComponent setLoggedIn={setLogin}/>}/>)
                 }

            </Routes>
        </BrowserRouter>

    );
}

export default App;
