import {firestore, storage} from "./firebase";
import { collection, query, where, getDocs, addDoc, deleteDoc, updateDoc, doc } from "@firebase/firestore";
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage';
const collectionName = 'projectCards';

export async function getAsync(searchQuery) {
    const q = query(
        collection(firestore, collectionName),
        where('header', '>=', searchQuery),
        where('header', '<=', searchQuery + '\uf8ff')
    );

    const result = await getDocs(q);

    return result.docs.map(x => ({
        id: x.id,
        description: x.data().description,
        name: x.data().header,
        madeIn: x.data().madeIn,
        image: x.data().src
    }));
}

export async function getAllAsync() {
    const q = query(collection(firestore, collectionName));

    const result = await getDocs(q);

    return result.docs.map(x => ({
        id: x.id,
        description: x.data().description,
        name: x.data().header,
        madeIn: x.data().madeIn,
        image: x.data().src
    }));
}

export function getAllMadeWith() {
    return ['unity','cs','kotlin','playfab','ps','android','figma','xamarin','sql','js','react','html','css','tailwind','firebase'];
}

export async function createAsync({name, desc, img, madeIn}) {
    const imageUrl = await uploadFileAsync(img);

    await addDoc(collection(firestore, collectionName), {
        header: name,
        description: desc,
        src: imageUrl,
        madeIn: madeIn
    });
}

export async function deleteAsync(docId) {
    try {
        await deleteDoc(doc(collection(firestore, collectionName), docId));
        console.log('Document successfully deleted');
    } catch (error) {
        console.error('Error deleting document: ', error);
        throw error;
    }
}

export async function updateAsync({ docId, name, desc, img, madeIn }) {
    try {
        const docRef = doc(collection(firestore, collectionName), docId);
        await updateDoc(docRef, {
            header: name,
            description: desc,
            src: img,
            madeIn: madeIn
        });
        console.log('Document successfully updated');
    } catch (error) {
        console.error('Error updating document: ', error);
        throw error;
    }
}

const uploadFileAsync = async (fileInput) => {
    const file = fileInput;
    const img = await resizeImage(file, 520, 960);
    console.log(img);
    if (file) {

        const randomGUID = generateGUID();
        const storageRef = ref(storage, randomGUID);

        await uploadBytes(storageRef, img);

        return await getDownloadURL(storageRef);
    }

    return null;
}


function resizeImage(file, targetWidth, targetHeight) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        const reader = new FileReader();

        reader.onload = function (event) {
            image.src = event.target.result;

            image.onload = function () {
                const sourceWidth = image.width;
                const sourceHeight = image.height;

                // Calculate the crop ratio based on the target dimensions
                const cropRatio = targetWidth / targetHeight;

                const aspectRatio = sourceWidth / sourceHeight;

                // Calculate dimensions after cropping to the specified aspect ratio
                let newWidth, newHeight;
                if (aspectRatio > cropRatio) {
                    newHeight = sourceHeight;
                    newWidth = sourceHeight * cropRatio;
                } else {
                    newWidth = sourceWidth;
                    newHeight = sourceWidth / cropRatio;
                }

                // Calculate the cropping offset
                const xOffset = (sourceWidth - newWidth) / 2;
                const yOffset = (sourceHeight - newHeight) / 2;

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = newWidth;
                canvas.height = newHeight;

                ctx.drawImage(image, -xOffset, -yOffset, sourceWidth, sourceHeight);

                // Now, resize the cropped image to fit within the specified dimensions
                let resizedWidth, resizedHeight;
                if (newWidth / targetWidth > newHeight / targetHeight) {
                    resizedWidth = targetWidth;
                    resizedHeight = targetWidth / (newWidth / newHeight);
                } else {
                    resizedHeight = targetHeight;
                    resizedWidth = targetHeight * (newWidth / newHeight);
                }

                const resizedCanvas = document.createElement('canvas');
                const resizedCtx = resizedCanvas.getContext('2d');
                resizedCanvas.width = resizedWidth;
                resizedCanvas.height = resizedHeight;

                resizedCtx.drawImage(canvas, 0, 0, newWidth, newHeight, 0, 0, resizedWidth, resizedHeight);

                resizedCanvas.toBlob((blob) => {
                    // Create a new File object with the same properties as the original file
                    const resizedFile = new File([blob], file.name, { type: file.type, lastModified: file.lastModified });
                    resolve(resizedFile);
                }, file.type, 0.9); // You can change the quality if needed
            };

            image.onerror = function (error) {
                reject(error);
            };
        };

        reader.onerror = function (error) {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
}

function generateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}