import React from 'react';

const Sidebar = () => {
    return (
        <div className={`fixed bg-gray-800 bg-opacity-75 z-50 'block'`}>
            <div className="fixed inset-y-0 left-0 w-64 bg-amber-200 shadow-xl z-50">
                <div className="flex justify-between items-center px-4 py-2 m-4 border-gray-200">
                    <h1 className="text-lg m-auto font-semibold">VERDANT PANEL</h1>
                </div>
                <div className="px-4 py-6">
                    <ul>
                        <li className="mb-4 h-auto p-2 cursor-default
                        bg-white text-center hover:bg-amber-50 rounded-lg"><a href="./upload">Upload Project</a></li>
                        <li className="mb-4 h-auto p-2 cursor-default
                        bg-white text-center hover:bg-amber-50 rounded-lg"><a href="./projects">See Projects</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;