import Sidebar from "./Sidebar";
import ProjectCard from "./ProjectCard";

export default function Projects({projectsList, onItemDeleted}) {
    return (
        <>
            <Sidebar/>
            <div className="flex ml-[20vw] justify-around">
        {
            projectsList.map((x) => {
                return <ProjectCard key={x.id}
                    id={x.id}
                    title={x.name}
                    image={x.image}
                    description={x.description}
                    madeIn={x.madeIn} onDelete={onItemDeleted}
                />
            })
        }
            </div>
        </>
    );
}