import React, { useState } from 'react';
import {createAsync} from "../firebase/FireStoreService";

const Form = ({ checkboxOptions }) => {
    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);
    const [checkboxes, setCheckboxes] = useState(
        checkboxOptions.reduce((acc, option) => {
            acc[option] = false;
            return acc;
        }, {})
    );
    const [description, setDescription] = useState('');

    const handleCheckboxChange = (option) => {
        setCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [option]: !prevCheckboxes[option],
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        await createAsync({
            desc:description,
            img:file,
            madeIn: Object.entries(checkboxes)
                .filter(([_, checked]) => checked)
                .map(([title, _]) => title),
            name:title
        });

        alert('sent');
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
            <div className="mt-6 mb-6">
                <label htmlFor="title" className="block text-gray-700 font-bold mb-2">Title</label>
                <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="file" className="block text-gray-700 font-bold mb-2">File</label>
                <input
                    type="file"
                    id="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Options</label>
                <div className="flex flex-wrap">
                {checkboxOptions.map((option) => (
                    <div key={option} className="mb-2">
                        <input
                            type="checkbox"
                            id={option}
                            checked={checkboxes[option]}
                            onChange={() => handleCheckboxChange(option)}
                            className="m-3.5"
                        />
                        <label htmlFor={option} className="text-gray-700">{option}</label>
                    </div>
                ))}
                </div>
            </div>
            <div className="mb-4">
                <label htmlFor="description" className="block text-gray-700 font-bold mb-2">Description</label>
                <textarea
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <button onClick={handleSubmit} type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Submit</button>
        </form>
    );
};

export default Form;