import React, { useState } from 'react';
import {deleteAsync, updateAsync} from "../firebase/FireStoreService";

const ProjectCard = ({ id, title: initialTitle, description: initialDescription, image, madeIn, onDelete }) => {
    const [title, setTitle] = useState(initialTitle);
    const [description, setDescription] = useState(initialDescription);
    const [isEditing, setIsEditing] = useState(false);

    const handleEditSave = async () => {
        await updateAsync({docId:id, name:title, desc:description, img:image, madeIn:madeIn});
        setIsEditing(false);
    };

    const handleDelete = async () => {
        await deleteAsync(id);
        onDelete();
    }

    const handleCancelEdit = () => {
        setTitle(initialTitle);
        setDescription(initialDescription);
        setIsEditing(false);
    };

    return (
        <div className="max-w-sm w-[18vw] rounded overflow-hidden shadow-lg">
            <img src={image} alt={title} className="w-full" />
            <div className="px-6 py-4">
                {isEditing ? (
                    <>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="font-bold text-xl mb-2 w-full"
                        />
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="text-gray-700 text-base w-full"
                        />
                    </>
                ) : (
                    <>
                        <div className="font-bold text-xl mb-2">{title}</div>
                        <p className="text-gray-700 text-base">{description}</p>
                    </>
                )}
            </div>
            <div className="px-6 py-4">
                {isEditing ? (
                    <>
                        <button onClick={handleEditSave} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2">Save</button>
                        <button onClick={handleCancelEdit} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cancel</button>
                    </>
                ) : (
                    <button onClick={() => setIsEditing(true)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Edit</button>
                )}
                <button onClick={() => handleDelete()} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2">Delete</button>
            </div>
        </div>
    );
};

export default ProjectCard;