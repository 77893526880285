import {useEffect, useState} from "react";
import {getAllAsync} from "../firebase/FireStoreService";
import Projects from "../component/Projects";

export default function ProjectsPage(props) {
    const [projects, setProjects] = useState([]);

    const getPjs = async () => {
        const result = await getAllAsync();
        setProjects(result);
    }

    const onDelete = () => {
        getPjs();
    }

    useEffect(() => {
        getPjs();
    },[]);

    return <Projects onItemDeleted={onDelete} projectsList={projects}/>
}