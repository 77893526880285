import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyBGtfc4gWUE6SUDzP2xvejKRJHbLYYeTmc",
    authDomain: "verdantdev-co.firebaseapp.com",
    databaseURL: "https://verdantdev-co-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "verdantdev-co",
    storageBucket: "verdantdev-co.appspot.com",
    messagingSenderId: "863450422378",
    appId: "1:863450422378:web:6b5ed066febac112b8be13",
    measurementId: "G-M05GBG9NN2"
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const storage = getStorage(app, firebaseConfig.storageBucket);