import Sidebar from "../component/Sidebar";
import Form from "../component/Form";
import {getAllMadeWith} from "../firebase/FireStoreService";

export default function UploadProjectsPage() {


    return <div className="App flex">
        <Sidebar/>
        <Form checkboxOptions={getAllMadeWith()}/>
    </div>
}